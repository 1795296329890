import http from "../axios/http"

//话术审核列表（后端）
export let getExaminedList = (params) => {
    return http.get("/examined/examined_list", { params })
}
//话术审核，知识库审核，等级审核（后端）
export let getSpeechTech = (params) => {
    return http.get("/examined/speech_tech", { params })
}
//话术审核（后端）
export let postOperator = (params) => {
    return http.post("/examined/examined_operator",  params )
}
