<template>
  <div class="CheckIndex page">
    <div class="top" style="margin-bottom: 20px">
      <a-input
        placeholder="话术模板"
        style="margin-right: 20px; width: 200px"
      ></a-input>
      <a-select placeholder="审核状态" style="margin-right: 20px; width: 200px">
        <a-select-option value="0">待审核</a-select-option>
        <a-select-option value="1">审核通过</a-select-option>
        <a-select-option value="2">审核不通过</a-select-option>
      </a-select>
      <a-button type="primary">
        <template #icon><SearchOutlined /></template>
        搜索
      </a-button>
    </div>
    <a-table :columns="columns" :data-source="data" rowKey="index">
      <template #status="{ record }">
        {{ handleStatus(record.status) }}
      </template>
      <template #create_time="{ record }">
        {{ formatDate(record.create_time) }}
      </template>
      <template #operation="{ record }">
        <a style="margin-right: 4px" @click="handleEdit(record)">查看</a>
        <a type="primary" size="small" @click="downLoad(record)">下载</a>
      </template>
    </a-table>

    <a-modal
      v-model:visible="visible"
      title="话术审核"
      centered
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      class="check-model"
      :width="800"
      @ok="handleOk"
    >
      <a-collapse v-model:activeKey="activeKey">
        <a-collapse-panel key="1" header="话术审核">
          <div
            class="content"
            v-for="item in formSatete.node_list"
            :key="item.snw_id"
          >
            <a-textarea
              class="textarea"
              v-model:value="item.content"
            ></a-textarea>
            <div class="audio">
              <audio
                controls
                :src="'/' + item.upload_path"
              ></audio>
            </div>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="2" header="知识库审核">
          <div
            class="content"
            v-for="item in formSatete.tech_list"
            :key="item.stw_id"
          >
            <a-textarea
              class="textarea"
              v-model:value="item.content"
            ></a-textarea>
            <div class="audio">
              <audio
                controls
                :src="'/' + item.upload_path"
              ></audio>
            </div>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="3" header="意向标签审核">
          <div
            class="content"
            v-for="item in formSatete.rule_list"
            :key="item.lab_id"
          >
            <a-textarea
              class="textarea"
              v-model:value="item.level"
            ></a-textarea>
            <a-textarea class="textarea" v-model:value="item.name"></a-textarea>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <a-form style="margin-top: 15px">
        <a-form-item label="审核结果" required>
          <a-radio-group v-model:value="formSatete.value">
            <a-radio value="1">通过</a-radio>
            <a-radio value="2">不通过</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="备注信息">
          <a-textarea
            placeholder="请输入"
            v-model:value="formSatete.beixhu"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import {
  getExaminedList,
  getSpeechTech,
  postOperator,
} from "../../../service/checkindex.js";
import { formatDate } from "../../../utils/function.js";
import { message } from "ant-design-vue";
const columns = [
  {
    title: "序号",
    dataIndex: "index",
  },
  {
    title: "话术模板",
    dataIndex: "talk_temp_name",
  },
  {
    title: "公司名称",
    dataIndex: "b",
  },
  {
    title: "提交审核",
    dataIndex: "create_time",
    slots: {
      customRender: "create_time",
    },
  },
  {
    title: "状态",
    dataIndex: "status",
    slots: {
      customRender: "status",
    },
  },
  {
    title: "审核人",
    dataIndex: "e",
  },
  {
    title: "审核信息",
    dataIndex: "fail_msg",
  },
  {
    title: "审核完成",
    dataIndex: "f",
  },
  {
    title: "操作",
    fixed: "right",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
];
export default defineComponent({
  name: "CheckIndex",
  components: { SearchOutlined },
  setup() {
    const state = reactive({
      data: [
        // {
        //   index: 1,
        //   a: "税务局呼入",
        //   b: "深圳锋之云科技有限公司",
        //   c: "2021-05-24 11:45:08",
        //   d: "审核成功",
        //   e: "admin",
        //   f: "2021-05-24 11:45:08",
        // },
      ],
      page: 1,
      status: undefined,
      name: undefined,
      visible: false,
      activeKey: ["1", "2", "3"],
      exam_id: undefined,
    });
    const formSatete = reactive({
      node_list: [],
      rule_list: [],
      tech_list: [],
      value: "",
      beixhu: undefined,
    });
    onMounted(() => {
      init();
    });
    const init = async () => {
      const res = await getExaminedList({
        access_token: sessionStorage.getItem("token"),
        page: state.page,
        status: state.status,
        name: state.name,
      });

      console.log(res);
      state.data = res.data.data.list;
      state.data.forEach((item, index) => (item["index"] = index + 1));
    };
    const handleStatus = (status) => {
      switch (status) {
        case "1":
          return "待审核";
        case "2":
          return "审核不通过";
        case "3":
          return "审核通过";
        case "4":
          return "用户取消";
      }
    };
    const handleEdit = async (record) => {
      // console.log(talk_temp_id);
      state.exam_id = record.exam_id;
      const res = await getSpeechTech({
        access_token: sessionStorage.getItem("token"),
        speech_id: record.talk_temp_id,
      });
      console.log(res);
      let arr = res.data.data.label_list;
      res.data.data.rule_list.forEach((item) => {
        let obj = arr.find((item2) => item2.lab_id === item.lab_id);
        item.level = obj.name;
      });
      formSatete.tech_list = res.data.data.tech_list;
      formSatete.rule_list = res.data.data.rule_list;
      formSatete.node_list = res.data.data.node_list;
      state.visible = true;
    };
    const downLoad = (recoed) => {
      console.log(recoed);
    };
    const handleOk = async () => {
      // if (formSatete.value === "1") {
      //   console.log("通过");

      // } else if (formSatete.value === "2") {
      //   console.log("不通过");
      // } else {
      //   console.log("未选择");
      // }
      if (!formSatete.value) return;
      const res = await postOperator({
        access_token: sessionStorage.getItem("token"),
        exam_id: state.exam_id,
        status: formSatete.value,
        fail_msg: state.beixhu,
      });
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        init();
        state.visible = false;
      } else {
        message.error(res.data.msg);
      }
    };
    return {
      columns,
      ...toRefs(state),
      formatDate,
      handleStatus,
      handleEdit,
      downLoad,
      formSatete,
      handleOk,
    };
  },
});
</script>
<style>
.check-model .content {
  display: flex;
  margin-bottom: 7px;
  justify-content: space-between;
  align-items: center;
}
.check-model .content .textarea {
  width: 49%;
}
.check-model .content .audio {
  width: 50%;
  height: 54px;
  border: 1px solid #ccc;
}

.check-model .content .audio audio {
  margin-top: 10px;
  width: 100%;
  height: 34px;
}
.check-model .ant-modal-body {
  height: 680px;
}
.ant-collapse {
  height: 500px;
  overflow: auto;
}
</style>
<style scoped>
.CheckIndex {
  padding: 15px;
}
</style>